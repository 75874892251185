.navbarintake {
  background: #ffffff;
  background: color(display-p3 1 1 1);
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 4px 5px color(display-p3 0 0 0 / 0.04);
  border-radius: 16px;
}

.navbar-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  align-self: center;
}

.caddiAssistant {
  /*Hacky way to be improved*/
  max-width: 400px;
}

.ellipse {
  position: relative;
  /* width: 116px;
    height: 116px; */
  width: 40%;
  height: 0;
  padding-bottom: 40%;
  min-width: 67px;
  min-height: 67px;
  left: 50%;
  transform: translateX(-50%);

  background: radial-gradient(
    50% 50% at 50% 50%,
    #41c783 0%,
    rgba(65, 199, 131, 0.729167) 50.52%,
    rgba(65, 199, 131, 0) 100%
  );
  background: radial-gradient(
    50% 50% at 50% 50%,
    color(display-p3 0.416 0.769 0.537) 0%,
    color(display-p3 0.416 0.769 0.537 / 0.729167) 50.52%,
    color(display-p3 0.416 0.769 0.537 / 0) 100%
  );
}

.caddiMark {
  position: absolute;
  width: 60%;
  height: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navigation {
  /* Flexbox styles for vertical alignment */
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* Make the box scrollable if content is larger than the box */
  max-height: 70vh;
  overflow-y: auto;
}

.imageNavbar {
  /*Hacky way to be improved*/
  max-width: 400px;
}
