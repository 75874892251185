.navbarfooter {
  background: #ffffff;
  background: color(display-p3 1 1 1);
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 4px 5px color(display-p3 0 0 0 / 0.04);
  border-radius: 16px;
}

/* Style for the circles */
.circle {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 5px;
}

.circle.active {
  /* background-color: #333; /* Change the color of the active circle */
  background-color: #3cba9f;
  /* width: 10px;
    height: 10px; */
}

/* Style for the progress bar */
.progress-bar {
  /* background-color: #eee; */
  background-color: #ccc;
  height: 8px;
  border-radius: 5px;
  margin-top: 10px;
  width: 100%; /* Adjust as needed */
}

.progress-bar-fill {
  /*background-color: #333; /* Change the color of the progress */
  background-color: #3cba9f;
  height: 100%;
  border-radius: 5px;
  transition: width 0.5s ease; /* Smooth transition effect */
}
