.card {
  border-radius: 2.8323px;
  background-color: #ffffff;
}

/* Highlight selected sector */
.is-selected {
  background-color: #dfefe0;
  border: 1px solid #689569;
}

.header {
  /* Make the divider stronger*/
  border-bottom: 1px solid #ccc;
}
