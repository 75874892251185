.document-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  /* If you want the button to appear on the right side */
  margin-left: auto;
}
