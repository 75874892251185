.column-divider {
  border-left: 0.5px solid #ccc; /* Adjust the line color and style as needed */
  height: 100%;
  margin: 0;
  padding: 0;
}

.divider {
  height: 1px;
  background-color: #ccc; /* Adjust the divider color as needed */
  margin: 10px 0; /* Adjust the margin as needed */
}

.button-box {
  transition: background-color 0.3s;
  cursor: pointer;
}

.hovered {
  background-color: #f0f0f0; /* Adjust this color as needed */
}

.clientsTable {
  /* max-height: 70vh; */
  /* max-height: 50%; */

  background: #ffffff;
  background: color(display-p3 1 1 1);
  border-radius: 12px;

  /* Make it scrollable but the first line*/
  /* overflow-y: scroll;
  overflow-x: hidden; */

  /* Make it pretty */
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);

  /* Make it not have any padding */
  padding: 0;
}

.clientCards {
  max-height: 65vh;
  /* Make it scrollable but the first line*/
  overflow-y: scroll;
  overflow-x: hidden;
}

.tableSection {
  height: 100%;
}

.dashboardMargins {
  min-height: 100vh;
  background-color: white;
}

.page {
  min-height: 100vh;
}
