.dark-green-box {
  background: linear-gradient(
    114.57deg,
    #68d1b2 0.53%,
    #57ce8d 46.63%,
    #d5f8d0 100%
  );
  background: linear-gradient(
    114.57deg,
    color(display-p3 0.514 0.812 0.706) 0.53%,
    color(display-p3 0.471 0.796 0.573) 46.63%,
    color(display-p3 0.863 0.969 0.827) 100%
  );
  border-radius: 0px;
  /* padding: 10%; */
  padding: 30px;
  /* padding-bottom: 100vh; */
  min-height: 100vh;
}

.white-box {
  border-radius: 24.3933px 0 0 24.3933px;
  min-height: 80vh;
  max-height: 85vh;
}

.light-green-box {
  background: rgba(255, 255, 255, 0.8);
  background: color(display-p3 1 1 1 / 0.8);
  backdrop-filter: blur(7.50562px);
  border-radius: 0 24.3933px 24.3933px 0;
  /* padding: 10%; */
  padding: 30px;
  /* padding-left: 5%; */
  margin-bottom: 1.5rem;
  /* height: 650px; */
  /* min-height: 650px; */
  min-height: 80vh;
  max-height: 85vh;

  /* Flexbox styles for vertical alignment */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Make the box scrollable if content is larger than the box */
  overflow-y: auto;
}

.welcome-box {
  border-radius: 24.3933px;
}
