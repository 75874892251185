 .input-field {
       background-color: #141B18; /* Dark grey background */
       color: #FFFFFF; /* White text */
       border: 1px solid #404040; /* Slightly lighter grey border */
       border-radius: 4px; /* Rounded borders */
       padding: 10px 15px; /* Some padding inside the input */
       font-size: 16px; /* Standard font size */
       margin-bottom: 10px; /* Space below each input */
       width : 50%; /* Full width */
     }
    
     .submit-button {
       background-color: #F5C155; /* Color of the button */
       color: #000000; /* Black text */
       border: none; /* No border */
       border-radius: 4px; /* Rounded borders */    
       padding: 10px 15px; /* Padding inside the button */
       text-transform: uppercase; /* Capitalize text */
       cursor: pointer; /* Pointer cursor on hover */
       font-size: 16px; /* Standard font size */
       font-weight: bold; /* Bold font */
       margin-top: 20px; /* Space above the button */
       justify-content: center; /* Center the button */
       display: flex; /* Center the button */
 }

 .centered-button {
  display: flex;
  justify-content: center;
  align-items: center; 
}