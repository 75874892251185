

.menu.collapsed {
  width: 70px; /* Adjust the collapsed width as needed */
}

.collapse-toggle {
  cursor: pointer;
  color: #fff;
  text-align: right;
  background-color: #1b453c;
}

.collapse-toggle:hover {
  background-color: #fff;
  color: #1b453c;
}

.lateral-menu-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%; /* Adjust height as necessary */
}

.menu {
  width: 168px;
  height: 100%;
  /* Dark/2E464B */
  background: #1b453c;
  background: color(display-p3 0.149 0.267 0.235);
}


.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.menu-spacer {
  flex-grow: 1;
}

.settings-menu {
  /* If you need to use absolute positioning */
  position: absolute;
  bottom: 0;
  width: 100%;
}

/* Ensure the hover logic applies the color swap correctly */
.has-text-white:hover {
  color: white;
}

.has-text-success:hover {
  color: #23d160;
}
