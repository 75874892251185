/* Greyed-out box styles */
.greyed-out {
  background-color: #ccc; /* Use the desired grey color */
  opacity: 0.7; /* Adjust the opacity as needed */
}

/* Greyed-out text styles */
.greyed-out-text {
  color: #777; /* Use a darker grey for the text */
}

/* Hover effect styles */
.hovered {
  /* Define your hover effect styles here */
  /* Example: add a box-shadow on hover */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
