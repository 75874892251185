:root {
  --taiga: #0d1714;
  --base-color-brand--white: white;
  --pistachio: #dcf7d3;
  --text-color--text-primary: var(--base-color-neutral--black);
  --link-color--link-primary: var(--base-color-neutral--black);
  --border-color--border-primary: var(--base-color-neutral--black);
  --evergreen: #26443c;
  --golf-green: #6ac489;
  --base-color-brand--black: black;
  --base-color-neutral--white: white;
  --base-color-neutral--black: black;
  --black-archive\<deleted\|variable-dccb7db6\>: black;
  --background-color--background-secondary: var(--base-color-neutral--neutral-lightest);
  --link-color--link-alternate: var(--base-color-neutral--white);
  --background-color--background-primary: var(--base-color-neutral--white);
  --background-color--background-tertiary: var(--base-color-neutral--neutral-light);
  --background-color--background-alternate: var(--base-color-neutral--black);
  --text-color--text-alternate: var(--base-color-neutral--white);
  --text-color--text-secondary: var(--base-color-neutral--black);
  --background-color--background-success: var(--base-color-system--success-green-light);
  --text-color--text-success: var(--base-color-system--success-green);
  --background-color--background-error: var(--base-color-system--error-red-light);
  --text-color--text-error: var(--base-color-system--error-red);
  --dark-olive-green: #12211d;
  --marigold: #f5c155;
  --dark-brown: #3a2d12;
  --base-color-neutral--neutral-lightest: #eee;
  --base-color-neutral--neutral-lighter: #ccc;
  --base-color-neutral--neutral-light: #aaa;
  --base-color-neutral--neutral: #666;
  --base-color-neutral--neutral-dark: #444;
  --base-color-neutral--neutral-darker: #222;
  --base-color-neutral--neutral-darkest: #111;
  --base-color-system--success-green: #027a48;
  --base-color-system--success-green-light: #ecfdf3;
  --base-color-system--error-red: #b42318;
  --base-color-system--error-red-light: #fef3f2;
  --border-color--border-secondary: var(--base-color-neutral--neutral-light);
  --border-color--border-alternate: var(--base-color-neutral--white);
  --link-color--link-secondary: var(--base-color-neutral--neutral-light);
}

.dropdown-menu {
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  opacity: 0;
  transform: translateY(-10px);
}

.dropdown-menu.show {
  opacity: 1;
  transform: translateY(0);
}

.menu-items li {
  list-style: none;
  padding: 0.5em 0;
  cursor: pointer;
}

.menu-button {
  background-color: #4CAF50; /* Green background */
  color: white;
  text-align: center;
  padding: 0.5em;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1em;
}



.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox:before {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox:after {
  content: " ";
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-width: 4px;
  border-color: #3898ec;
}

body {
  background-color: var(--taiga);
  color: rgba(255, 255, 255, .6);
  font-family: Instrument Sans, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

h1 {
  color: var(--base-color-brand--white);
  margin-top: 0;
  margin-bottom: 0;
  font-size: 4.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h2 {
  color: var(--base-color-brand--white);
  margin-top: 0;
  margin-bottom: 0;
  font-size: 3rem;
  font-weight: 500;
  line-height: 1.2;
}

h3 {
  color: var(--base-color-brand--white);
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.2;
}

h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.3;
}

h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.4;
}

h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.4;
}

p {
  margin-bottom: 0;
}

a {
  color: var(--base-color-brand--white);
  text-decoration: none;
  transition: all .3s;
}

a:hover {
  color: var(--pistachio);
}

ul {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1.25rem;
}

ol {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
}

li {
  margin-top: .25rem;
  margin-bottom: .25rem;
  padding-left: .5rem;
}

img {
  max-width: 100%;
  display: inline-block;
}

label {
  margin-bottom: .25rem;
  font-weight: 500;
}

blockquote {
  border-left: .1875rem solid var(--text-color--text-primary);
  margin-bottom: 0;
  padding: .75rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

figure {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

figcaption {
  text-align: center;
  margin-top: .25rem;
}

.z-index-2 {
  z-index: 2;
  position: relative;
}

.max-width-full {
  width: 100%;
  max-width: none;
}

.layer {
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.overflow-hidden {
  overflow: hidden;
}

.global-styles {
  display: block;
  position: fixed;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: auto;
}

.z-index-1 {
  z-index: 1;
  position: relative;
}

.hide {
  display: none;
}

.text-style-link {
  color: var(--link-color--link-primary);
  text-decoration: underline;
}

.max-width-xlarge {
  width: 100%;
  max-width: 55rem;
}

.max-width-medium {
  width: 100%;
  max-width: 35rem;
}

.max-width-xsmall {
  width: 100%;
  max-width: 25rem;
}

.max-width-xxsmall {
  width: 100%;
  max-width: 20rem;
}

.container-large {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.max-width-xxlarge {
  width: 100%;
  max-width: 80rem;
}

.align-center {
  margin-left: auto;
  margin-right: auto;
}

.spacing-clean {
  margin: 0;
  padding: 0;
}

.max-width-large {
  width: 100%;
  max-width: 48rem;
}

.max-width-small {
  width: 100%;
  max-width: 30rem;
}

.utility_component {
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  display: flex;
}

.utility_form-block {
  max-width: 20rem;
  text-align: center;
  flex-direction: column;
  display: flex;
}

.utility_form {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.utility_image {
  margin-bottom: .5rem;
  margin-left: auto;
  margin-right: auto;
}

.container-medium {
  width: 100%;
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;
}

.container-small {
  width: 100%;
  max-width: 48rem;
  margin-left: auto;
  margin-right: auto;
}

.margin-tiny {
  margin: .25rem;
}

.margin-xxsmall {
  margin: .5rem;
}

.margin-xsmall {
  margin: 1rem;
}

.margin-small {
  margin: 1.5rem;
}

.margin-medium {
  margin: 2rem;
}

.margin-large {
  margin: 3rem;
}

.margin-xlarge {
  margin: 4rem;
}

.margin-xxlarge {
  margin: 5rem;
}

.margin-huge {
  margin: 6rem;
}

.margin-xhuge {
  margin: 7rem;
}

.margin-xxhuge {
  margin: 10rem;
}

.margin-0 {
  margin: 0;
}

.padding-0 {
  padding: 0;
}

.padding-tiny {
  padding: .25rem;
}

.padding-xxsmall {
  padding: .5rem;
}

.padding-xsmall {
  padding: 1rem;
}

.padding-small {
  padding: 1.5rem;
}

.padding-medium {
  padding: 2rem;
}

.padding-large {
  padding: 3rem;
}

.padding-xlarge {
  padding: 4rem;
}

.padding-xxlarge {
  padding: 5rem;
}

.padding-huge {
  padding: 6rem;
}

.padding-xhuge {
  padding: 7rem;
}

.padding-xxhuge {
  padding: 10rem;
}

.margin-top, .margin-top.margin-custom1, .margin-top.margin-huge, .margin-top.margin-small, .margin-top.margin-tiny, .margin-top.margin-custom3, .margin-top.margin-medium, .margin-top.margin-xxsmall, .margin-top.margin-xxhuge, .margin-top.margin-custom2, .margin-top.margin-xsmall, .margin-top.margin-large, .margin-top.margin-xxlarge, .margin-top.margin-xlarge, .margin-top.margin-0, .margin-top.margin-xhuge {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

.margin-bottom, .margin-bottom.margin-xxlarge, .margin-bottom.margin-medium, .margin-bottom.margin-custom3, .margin-bottom.margin-custom1, .margin-bottom.margin-tiny, .margin-bottom.margin-small, .margin-bottom.margin-large, .margin-bottom.margin-0, .margin-bottom.margin-xlarge, .margin-bottom.margin-xhuge, .margin-bottom.margin-xxhuge, .margin-bottom.margin-huge, .margin-bottom.margin-xxsmall, .margin-bottom.margin-custom2, .margin-bottom.margin-xsmall {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.margin-left, .margin-left.margin-tiny, .margin-left.margin-xxlarge, .margin-left.margin-0, .margin-left.margin-small, .margin-left.margin-xsmall, .margin-left.margin-large, .margin-left.margin-huge, .margin-left.margin-xlarge, .margin-left.margin-custom3, .margin-left.margin-xxsmall, .margin-left.margin-custom1, .margin-left.margin-custom2, .margin-left.margin-xhuge, .margin-left.margin-xxhuge, .margin-left.margin-medium {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
}

.margin-right, .margin-right.margin-huge, .margin-right.margin-xsmall, .margin-right.margin-xxhuge, .margin-right.margin-small, .margin-right.margin-medium, .margin-right.margin-xlarge, .margin-right.margin-custom3, .margin-right.margin-custom2, .margin-right.margin-xxsmall, .margin-right.margin-xhuge, .margin-right.margin-custom1, .margin-right.margin-large, .margin-right.margin-0, .margin-right.margin-xxlarge, .margin-right.margin-tiny {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.margin-vertical, .margin-vertical.margin-medium, .margin-vertical.margin-xlarge, .margin-vertical.margin-xxsmall, .margin-vertical.margin-xxlarge, .margin-vertical.margin-huge, .margin-vertical.margin-0, .margin-vertical.margin-xxhuge, .margin-vertical.margin-tiny, .margin-vertical.margin-xsmall, .margin-vertical.margin-custom1, .margin-vertical.margin-small, .margin-vertical.margin-large, .margin-vertical.margin-xhuge, .margin-vertical.margin-custom2, .margin-vertical.margin-custom3 {
  margin-left: 0;
  margin-right: 0;
}

.margin-horizontal, .margin-horizontal.margin-medium, .margin-horizontal.margin-tiny, .margin-horizontal.margin-custom3, .margin-horizontal.margin-0, .margin-horizontal.margin-xxlarge, .margin-horizontal.margin-xhuge, .margin-horizontal.margin-huge, .margin-horizontal.margin-xlarge, .margin-horizontal.margin-custom1, .margin-horizontal.margin-xxsmall, .margin-horizontal.margin-xsmall, .margin-horizontal.margin-large, .margin-horizontal.margin-custom2, .margin-horizontal.margin-small, .margin-horizontal.margin-xxhuge {
  margin-top: 0;
  margin-bottom: 0;
}

.padding-top, .padding-top.padding-xhuge, .padding-top.padding-xxhuge, .padding-top.padding-0, .padding-top.padding-large, .padding-top.padding-custom2, .padding-top.padding-xlarge, .padding-top.padding-huge, .padding-top.padding-xxsmall, .padding-top.padding-custom3, .padding-top.padding-tiny, .padding-top.padding-medium, .padding-top.padding-xxlarge, .padding-top.padding-small, .padding-top.padding-xsmall, .padding-top.padding-custom1 {
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.padding-bottom, .padding-bottom.padding-xlarge, .padding-bottom.padding-small, .padding-bottom.padding-xxhuge, .padding-bottom.padding-large, .padding-bottom.padding-xhuge, .padding-bottom.padding-custom2, .padding-bottom.padding-xxsmall, .padding-bottom.padding-xxlarge, .padding-bottom.padding-custom3, .padding-bottom.padding-custom1, .padding-bottom.padding-xsmall, .padding-bottom.padding-huge, .padding-bottom.padding-tiny, .padding-bottom.padding-medium, .padding-bottom.padding-0 {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.padding-left, .padding-left.padding-xhuge, .padding-left.padding-xlarge, .padding-left.padding-0, .padding-left.padding-small, .padding-left.padding-xsmall, .padding-left.padding-medium, .padding-left.padding-xxhuge, .padding-left.padding-huge, .padding-left.padding-custom1, .padding-left.padding-tiny, .padding-left.padding-xxsmall, .padding-left.padding-custom3, .padding-left.padding-custom2, .padding-left.padding-xxlarge, .padding-left.padding-large {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
}

.padding-right, .padding-right.padding-xxhuge, .padding-right.padding-custom2, .padding-right.padding-large, .padding-right.padding-xlarge, .padding-right.padding-tiny, .padding-right.padding-custom3, .padding-right.padding-custom1, .padding-right.padding-xsmall, .padding-right.padding-xxlarge, .padding-right.padding-xxsmall, .padding-right.padding-medium, .padding-right.padding-xhuge, .padding-right.padding-huge, .padding-right.padding-0, .padding-right.padding-small {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.padding-vertical, .padding-vertical.padding-0, .padding-vertical.padding-xxhuge, .padding-vertical.padding-large, .padding-vertical.padding-custom3, .padding-vertical.padding-medium, .padding-vertical.padding-tiny, .padding-vertical.padding-xxlarge, .padding-vertical.padding-custom1, .padding-vertical.padding-xsmall, .padding-vertical.padding-xhuge, .padding-vertical.padding-xlarge, .padding-vertical.padding-custom2, .padding-vertical.padding-xxsmall, .padding-vertical.padding-huge, .padding-vertical.padding-small {
  padding-left: 0;
  padding-right: 0;
}

.padding-horizontal, .padding-horizontal.padding-xxsmall, .padding-horizontal.padding-0, .padding-horizontal.padding-custom3, .padding-horizontal.padding-huge, .padding-horizontal.padding-xsmall, .padding-horizontal.padding-medium, .padding-horizontal.padding-xxlarge, .padding-horizontal.padding-large, .padding-horizontal.padding-xhuge, .padding-horizontal.padding-custom1, .padding-horizontal.padding-tiny, .padding-horizontal.padding-custom2, .padding-horizontal.padding-xxhuge, .padding-horizontal.padding-small, .padding-horizontal.padding-xlarge {
  padding-top: 0;
  padding-bottom: 0;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-auto {
  overflow: auto;
}

.text-weight-medium {
  font-weight: 500;
}

.text-size-medium {
  font-size: 1.125rem;
}

.margin-top-auto {
  margin-top: auto;
}

.text-style-quote {
  border-left: .1875rem solid var(--text-color--text-primary);
  margin-bottom: 0;
  padding: .75rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.icon-embed-small {
  width: 2rem;
  height: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.heading-style-h2 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
}

.rl-styleguide_callout-link-wrapper-colors {
  width: 100%;
  background-image: linear-gradient(135deg, rgba(255, 116, 72, .15), rgba(255, 72, 72, .15) 50%, rgba(98, 72, 255, .15)), linear-gradient(#fff, #fff);
  border-radius: .6875rem;
  justify-content: space-between;
  align-items: center;
  padding: .75rem 1rem;
  display: flex;
}

.rl-styleguide_heading {
  z-index: 4;
  margin-bottom: 1.5rem;
  padding: .25rem .5rem;
  font-size: 3.5rem;
  font-weight: 700;
}

.heading-style-h6 {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.4;
}

.rl-styleguide_item-row {
  grid-column-gap: 1.5rem;
  grid-row-gap: 0rem;
  grid-template-rows: auto;
  grid-template-columns: 15rem 1fr;
  align-items: center;
}

.rl-styleguide_item-row.is-button-row {
  grid-template-columns: 19rem 1fr;
}

.icon-1x1-xsmall {
  width: 1.5rem;
  height: 1.5rem;
}

.rl-styleguide_empty-space {
  z-index: -1;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem;
  display: flex;
  position: relative;
}

.shadow-xxlarge {
  box-shadow: 0 32px 64px -12px rgba(0, 0, 0, .14);
}

.rl-styleguide_icons-list {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-wrap: wrap;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  display: grid;
}

.icon-embed-medium {
  width: 3rem;
  height: 3rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.form_radio {
  align-items: center;
  margin-bottom: 0;
  padding-left: 1.125rem;
  display: flex;
}

.heading-style-h5 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.4;
}

.text-style-strikethrough {
  text-decoration: line-through;
}

.heading-style-h1 {
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.2;
}

.form_checkbox-icon {
  width: 1.125rem;
  height: 1.125rem;
  min-height: 1.125rem;
  min-width: 1.125rem;
  border: 1px solid var(--border-color--border-primary);
  cursor: pointer;
  border-radius: 0;
  margin-top: 0;
  margin-left: -1.25rem;
  margin-right: .5rem;
  transition: all .2s;
}

.form_checkbox-icon.w--redirected-checked {
  box-shadow: none;
  background-color: #000;
  background-size: 16px 16px;
  border-width: 1px;
  border-color: #000;
}

.form_checkbox-icon.w--redirected-focus {
  box-shadow: none;
  border-color: #000;
}

.form_checkbox-label {
  margin-bottom: 0;
}

.text-size-tiny {
  font-size: .75rem;
}

.form_field-label {
  margin-bottom: .5rem;
  font-weight: 400;
}

.w-button {
  border: 1px solid var(--pistachio);
  background-color: var(--pistachio);
  color: var(--evergreen);
  text-align: center;
  border-radius: 6.25rem;
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  text-decoration: none;
  transition: all .3s;
}

.w-button:hover {
  border-color: var(--golf-green);
  background-color: var(--golf-green);
  color: var(--base-color-brand--black);
}

.button.is-link {
  color: var(--base-color-brand--white);
  background-color: rgba(0, 0, 0, 0);
  border-style: none;
  padding: .25rem 0;
  line-height: 1;
  text-decoration: none;
}

.button.is-link.is-alternate {
  color: var(--base-color-neutral--white);
  background-color: rgba(0, 0, 0, 0);
}

.button.is-link.is-icon {
  grid-column-gap: .5rem;
  grid-row-gap: .5rem;
}

.button.is-icon {
  grid-column-gap: .75rem;
  grid-row-gap: .75rem;
  justify-content: center;
  align-items: center;
  display: flex;
}

.button.is-secondary {
  color: var(--base-color-neutral--black);
  background-color: rgba(0, 0, 0, 0);
}

.button.is-secondary.is-alternate {
  color: var(--base-color-neutral--white);
  background-color: rgba(0, 0, 0, 0);
}

.button.is-small {
  padding: .5rem 1.25rem;
}

.button.is-small.is-icon-only {
  padding-left: .5rem;
  padding-right: .5rem;
}

.button.is-alternate {
  border-color: var(--base-color-neutral--white);
  background-color: var(--base-color-neutral--white);
  color: var(--base-color-neutral--black);
}

.button.is-tertiary {
  color: var(--base-color-neutral--black);
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

.button.is-icon-only {
  padding-left: .75rem;
  padding-right: .75rem;
}

.text-weight-normal {
  font-weight: 400;
}

.heading-style-h4 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.3;
}

.text-style-italic {
  font-style: italic;
}

.icon-1x1-xxsmall {
  width: 1rem;
  height: 1rem;
}

.form_radio-label {
  margin-bottom: 0;
}

.rl-styleguide_item {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-content: start;
  justify-content: start;
  align-items: start;
  justify-items: start;
  padding-bottom: 1rem;
  display: grid;
}

.rl-styleguide_item.is-stretch {
  grid-row-gap: 1rem;
  justify-items: stretch;
}

.icon-1x1-xlarge {
  width: 6.5rem;
  height: 6.5rem;
}

.shadow-xlarge {
  box-shadow: 0 24px 48px -12px rgba(0, 0, 0, .18);
}

.text-weight-light {
  font-weight: 300;
}

.rl-styleguide_label {
  color: #1e51f7;
  white-space: nowrap;
  cursor: context-menu;
  background-color: #ebeffa;
  padding: .25rem .5rem;
  font-size: .75rem;
  display: inline-block;
}

.rl-styleguide_label.is-html-tag {
  color: #bb4198;
  background-color: rgba(190, 74, 165, .1);
}

.text-size-regular {
  font-size: 1rem;
}

.text-weight-xbold {
  font-weight: 800;
}

.text-align-right {
  text-align: right;
}

.text-weight-bold {
  font-weight: 700;
}

.icon-height-xlarge {
  height: 6.5rem;
}

.heading-style-h3 {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.2;
}

.icon-1x1-large {
  width: 5rem;
  height: 5rem;
}

.form_input {
  height: auto;
  min-height: 2.75rem;
  border: 1px solid var(--base-color-neutral--black);
  background-color: var(--base-color-neutral--white);
  color: var(--text-color--text-primary);
  border-radius: 0;
  margin-bottom: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.6;
}

.form_input:focus {
  border-color: #000;
}

.form_input::-ms-input-placeholder {
  color: rgba(0, 0, 0, .6);
}

.form_input::placeholder {
  color: rgba(0, 0, 0, .6);
}

.form_input.is-text-area {
  height: auto;
  min-height: 11.25rem;
  padding-top: .75rem;
  padding-bottom: .75rem;
  overflow: auto;
}

.form_input.is-select-input {
  background-image: none;
}

.rl-styleguide_button-list {
  grid-column-gap: 1rem;
  grid-row-gap: 1.5rem;
  white-space: normal;
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-auto-columns: max-content;
  grid-auto-flow: row;
  align-items: center;
  justify-items: start;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: grid;
}

.rl-styleguide_list {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  padding-bottom: 4rem;
}

.icon-embed-custom1 {
  width: 1.25rem;
  height: 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.button-group {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.button-group.hero_button-group {
  grid-column-gap: 2.5rem;
  grid-row-gap: 2.5rem;
}

.text-color-white {
  color: var(--base-color-neutral--white);
}

.background-color-white {
  background-color: var(--base-color-neutral--white);
}

.text-weight-semibold {
  font-weight: 600;
}

.text-style-muted {
  opacity: .6;
}

.text-style-nowrap {
  white-space: nowrap;
}

.text-align-left {
  text-align: left;
}

.icon-embed-large {
  width: 5rem;
  height: 5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.shadow-xxsmall {
  box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
}

.text-color-black {
  color: var(--black-archive\<deleted\|variable-dccb7db6\>);
}

.icon-embed-xxsmall {
  width: 1rem;
  height: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.icon-embed-xsmall {
  width: 1.5rem;
  height: 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.icon-height-xxsmall {
  height: 1rem;
}

.background-color-black {
  background-color: var(--base-color-neutral--black);
}

.shadow-xsmall {
  box-shadow: 0 1px 3px rgba(0, 0, 0, .1), 0 1px 2px rgba(0, 0, 0, .06);
}

.icon-1x1-custom1 {
  width: 1.25rem;
  height: 1.25rem;
}

.rl-styleguide_header {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
}

.rl-styleguide_subheading {
  z-index: 3;
  background-color: var(--background-color--background-secondary);
  border-radius: .75rem;
  margin-bottom: 1rem;
  padding: 1rem;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
}

.form_radio-icon {
  width: 1.125rem;
  height: 1.125rem;
  min-height: 1.125rem;
  min-width: 1.125rem;
  border: 1px solid var(--border-color--border-primary);
  cursor: pointer;
  border-radius: 100px;
  margin-top: 0;
  margin-left: -1.125rem;
  margin-right: .5rem;
}

.form_radio-icon.w--redirected-checked {
  background-color: #fff;
  background-image: none;
  border-width: 6px;
  border-color: #000;
}

.form_radio-icon.w--redirected-focus {
  box-shadow: none;
  border-color: #000;
  margin-top: 0;
}

.rl-styleguide_shadows-list {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: start;
}

.form_form {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.text-style-allcaps {
  text-transform: uppercase;
}

.rl-styleguide_callout-link_colors {
  background-color: #fff;
  background-image: linear-gradient(135deg, #ff7448, #ff4848 50%, #6248ff);
  border-radius: .75rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding: 1px;
  text-decoration: none;
  display: flex;
  overflow: hidden;
}

.icon-height-custom1 {
  height: 1.25rem;
}

.text-align-center {
  text-align: center;
}

.text-size-small {
  font-size: .875rem;
}

.class-label-column {
  grid-column-gap: .75rem;
  grid-row-gap: .75rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.form_field-wrapper {
  position: relative;
}

.icon-height-small {
  height: 2rem;
}

.icon-height-large {
  height: 5rem;
}

.shadow-large {
  box-shadow: 0 20px 24px -4px rgba(0, 0, 0, .08), 0 8px 8px -4px rgba(0, 0, 0, .03);
}

.icon-embed-xlarge {
  width: 6.5rem;
  height: 6.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.icon-1x1-small {
  width: 2rem;
  height: 2rem;
}

.class-label-row {
  grid-column-gap: .25rem;
  grid-row-gap: .25rem;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.text-rich-text h1 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.text-rich-text blockquote {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-style: italic;
}

.text-rich-text h2, .text-rich-text h3, .text-rich-text h4 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.text-rich-text h5, .text-rich-text h6 {
  margin-top: 1.25rem;
  margin-bottom: 1rem;
}

.text-rich-text p {
  margin-bottom: 1rem;
}

.text-rich-text figcaption {
  border-left: 2px solid var(--text-color--text-primary);
  text-align: left;
  margin-top: .5rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.icon-height-medium {
  height: 3rem;
}

.form_checkbox {
  align-items: center;
  margin-bottom: 0;
  padding-left: 1.25rem;
  display: flex;
}

.shadow-small {
  box-shadow: 0 4px 8px -2px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .06);
}

.icon-1x1-medium {
  width: 3rem;
  height: 3rem;
}

.rl-styleguide_button-row {
  grid-column-gap: 1rem;
  display: flex;
}

.shadow-medium {
  box-shadow: 0 12px 16px -4px rgba(0, 0, 0, .08), 0 4px 6px -2px rgba(0, 0, 0, .03);
}

.text-size-large {
  font-size: 1.25rem;
}

.icon-height-xsmall {
  height: 1.5rem;
}

.pointer-events-auto {
  pointer-events: auto;
}

.rl-styleguide_spacing-all {
  display: none;
}

.pointer-events-none {
  pointer-events: none;
}

.padding-section-small {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.rl-styleguide_empty-box {
  z-index: -1;
  height: 3rem;
  min-width: 3rem;
  background-color: #eee;
  position: relative;
}

.rl-styleguide_spacing {
  border: 1px dashed #d3d3d3;
}

.padding-global {
  padding-left: 5%;
  padding-right: 5%;
}

.padding-section-medium {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.padding-section-large {
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
}

.spacer-tiny {
  width: 100%;
  padding-top: .25rem;
}

.spacer-xxsmall {
  width: 100%;
  padding-top: .5rem;
}

.spacer-xsmall {
  width: 100%;
  padding-top: 1rem;
}

.spacer-small {
  width: 100%;
  padding-top: 1.5rem;
}

.spacer-medium {
  width: 100%;
  padding-top: 2rem;
}

.spacer-large {
  width: 100%;
  padding-top: 3rem;
}

.spacer-xlarge {
  width: 100%;
  padding-top: 4rem;
}

.spacer-xxlarge {
  width: 100%;
  padding-top: 5rem;
}

.spacer-huge {
  width: 100%;
  padding-top: 6rem;
}

.spacer-xhuge {
  width: 100%;
  padding-top: 7rem;
}

.spacer-xxhuge {
  width: 100%;
  padding-top: 10rem;
}

.rl-styleguide_nav {
  z-index: 1000;
  background-color: #161616;
  border-radius: .75rem;
  margin-top: 1rem;
  padding: .5rem;
  position: -webkit-sticky;
  position: sticky;
  top: 1rem;
}

.rl-styleguide_nav-link {
  color: var(--link-color--link-alternate);
  border-radius: .25rem;
  padding: .5rem 1rem;
  text-decoration: none;
}

.rl-styleguide_nav-link.w--current {
  color: var(--link-color--link-primary);
  background-color: #fff;
}

.rl-styleguide_subheading-small {
  font-size: 1rem;
  font-weight: 400;
}

.rl-styleguide_callout-heading-wrapper {
  font-size: 1.25rem;
  font-weight: 600;
}

.rl-styleguide_background-color-list {
  grid-column-gap: 0rem;
  grid-row-gap: 0rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.background-color-primary {
  background-color: var(--background-color--background-primary);
}

.rl-styleguide_color-spacer {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
}

.background-color-secondary {
  background-color: var(--background-color--background-secondary);
}

.background-color-tertiary {
  background-color: var(--background-color--background-tertiary);
}

.background-color-alternative {
  background-color: var(--background-color--background-alternate);
}

.text-color-alternate {
  color: var(--text-color--text-alternate);
}

.text-color-secondary {
  color: var(--text-color--text-secondary);
}

.text-color-primary {
  color: var(--golf-green);
}

.aspect-ratio-square {
  aspect-ratio: 1 / 1;
}

.rl-styleguide_ratio-bg {
  z-index: -1;
  height: 100%;
  min-width: 3rem;
  background-color: #eee;
  position: relative;
}

.aspect-ratio-portrait {
  aspect-ratio: 2 / 3;
}

.aspect-ratio-landscape {
  aspect-ratio: 3 / 2;
}

.aspect-ratio-widescreen {
  aspect-ratio: 16 / 9;
}

.form_message-success-wrapper {
  padding: 0;
}

.form_message-success {
  background-color: var(--background-color--background-success);
  color: var(--text-color--text-success);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
  display: flex;
}

.form_message-error {
  background-color: var(--background-color--background-error);
  color: var(--text-color--text-error);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.form_message-error-wrapper {
  margin-top: 1rem;
  padding: 0;
}

.hero_content {
  max-width: 75rem;
  grid-row-gap: 2.5rem;
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.tagline {
  color: var(--base-color-brand--white);
  border: 1px solid rgba(106, 196, 137, .12);
  border-radius: 6.1875rem;
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.25;
  display: inline-block;
  box-shadow: 0 0 46.5px -12px #6ac489;
}

.hero_para {
  max-width: 58.25rem;
  color: var(--base-color-brand--white);
  margin-left: auto;
  margin-right: auto;
  font-size: 1.5rem;
}

.navbar4_component {
  width: 100%;
  min-height: 4.5rem;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(18, 33, 29, .95);
  border-bottom: 1px solid rgba(106, 196, 137, .2);
  align-items: center;
  padding: 1.25rem 5%;
  display: flex;
  position: fixed;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.menu-icon4 {
  width: 48px;
  height: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.menu-icon4_line-bottom {
  width: 24px;
  height: 2px;
  background-color: var(--golf-green);
}

.menu-icon4_line-middle {
  width: 24px;
  height: 2px;
  background-color: var(--golf-green);
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  margin-bottom: 6px;
  display: flex;
}

.menu-icon4_line-top {
  width: 24px;
  height: 2px;
  background-color: var(--golf-green);
  padding-bottom: 0;
  padding-right: 0;
}

.navbar4_wrapper {
  grid-column-gap: 1rem;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.navbar4_link {
  color: var(--base-color-brand--white);
  margin: .5rem auto;
  padding: .5rem 0;
  font-size: 1.75rem;
  font-weight: 500;
  transition: all .3s;
  display: inline-block;
  position: static;
}

.navbar4_link:hover {
  color: var(--pistachio);
}

.navbar4_link.w--current {
  color: rgba(0, 0, 0, 0);
}


.navbar4_menu-dropdown {
  margin-left: 0;
  font-size: 24px;
}

.navbar4_menu-wrapper {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -4.5rem;
  display: block;
}

/* Initially hide the menu */
.navbar4_menu-wrapper {
  display: none;
}

/* When .show-menu is added, the menu becomes visible */
.show-menu {
  display: block;
  z-index: 1000; 
}

.navbar4_container {
  width: 100%;
  height: 100%;
  grid-template-rows: auto;
  grid-template-columns: 1fr max-content 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.navbar4_menu {
  position: fixed;
  top: 100%; /* Initially hidden below the viewport */
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(18, 33, 29, 0.95); /* Adjust color to match your design */
  overflow: hidden;
  transition: top 0.5s ease; /* Smooth transition for the slide-down effect */
  z-index: 999; /* High z-index to ensure it's on top */
}

.navbar4_logo {
  height: 2.8125rem;
}

.dropdown-icon {
  width: 1rem;
  height: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1rem;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.dropdown-icon.is-navbar4 {
  width: 1.5rem;
  height: 1.5rem;
}

.navbar4_dropdown-link {
  margin-top: .5rem;
  margin-bottom: .5rem;
  margin-left: 1.5rem;
  padding: .5rem 0;
  font-size: 24px;
}

.navbar4_dropdown-link.w--current {
  color: rgba(0, 0, 0, 0);
}
.navbar4_dropdown-toggle {
  color: var(--base-color-brand--white);
  margin-top: .5rem;
  margin-bottom: .5rem;
  padding: .5rem 4rem .5rem 0;
}

.navbar4_dropdown-list {
  position: static;
  overflow: hidden;
}

.navbar4_dropdown-list.w--open {
  background-color: rgba(0, 0, 0, 0);
  position: static;
}

.navbar4_menu-button {
  padding: 0;
}

.navbar4_menu-button.w--open {
  background-color: rgba(0, 0, 0, 0);
}

.menu-icon_line-middle-top {
  z-index: 2;
  width: 24px;
  height: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.navbar4_logo-link {
  padding-left: 0;
}

.button-arrow {
  width: 1.5rem;
  height: 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.hero_component {
  grid-row-gap: 3.75rem;
  flex-direction: column;
  display: flex;
}

.hero_media-wrap {
  text-align: center;
}

.hero_media.mobile {
  display: none;
}

.section_hero {
  padding-top: 12.625rem;
  padding-bottom: 0;
  overflow: hidden;
}

.navbar-button-wrap-mobile {
  display: none;
}

.integration_grid {
  grid-column-gap: 5rem;
  grid-row-gap: 5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr auto 1fr auto 1fr;
}

.integration_card {
  grid-column-gap: 3.75rem;
  grid-row-gap: 3.75rem;
  flex-direction: column;
  display: flex;
}

.integration_card-thumbnail {
  width: 100%;
}

.integration_card-body {
  grid-row-gap: 1rem;
  flex-direction: column;
  display: flex;
}

.integration_card-shape {
  width: .6875rem;
  height: .6875rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.integration_card-divider {
  width: .125rem;
  background-image: linear-gradient(to bottom, rgba(106, 196, 137, 0), var(--golf-green) 50%, rgba(106, 196, 137, 0));
}

.client_grid {
  grid-column-gap: 2.5rem;
  grid-row-gap: 2.5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 3.75rem;
}

.client_card {
  grid-column-gap: 3.375rem;
  grid-row-gap: 3.375rem;
  background-image: linear-gradient(rgba(106, 196, 137, .05), rgba(106, 196, 137, 0));
  border: .0625rem solid rgba(106, 196, 137, .1);
  border-radius: .5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: flex-start;
  padding-top: 1.625rem;
  padding-left: 2.5rem;
  display: grid;
  position: relative;
}

.client_card._2 {
  display: block;
}

.client_card-body {
  max-width: 20.3125rem;
  grid-row-gap: 1rem;
  flex-direction: column;
  display: flex;
}

.client_card-body.v2 {
  max-width: 19.6875rem;
}

.client_card-thumbnail {
  width: 100%;
}

.client_card-thumbnail.absolute {
  width: auto;
  height: 9.375rem;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.section-divider {
  width: 100%;
  height: .125rem;
  background-image: linear-gradient(to right, rgba(106, 196, 137, 0), var(--golf-green) 50%, rgba(106, 196, 137, 0));
}

.meet_content {
  max-width: 66.5rem;
  grid-row-gap: 2.5rem;
  flex-direction: column;
  display: flex;
}

.meet-title {
  max-width: 49.375rem;
}

.section_footer {
  background-color: var(--dark-olive-green);
  padding-top: 5.8125rem;
  padding-bottom: 5.125rem;
}

.cta_content {
  z-index: 1;
  max-width: 66.5rem;
  flex-direction: column;
  display: flex;
  position: relative;
}

.cta_title {
  font-size: 7.75rem;
  display: inline-block;
}

.cta_component {
  margin-bottom: 9.5625rem;
  position: relative;
}

.cta_content-shape {
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.footer_component {
  grid-column-gap: 3.375rem;
  grid-row-gap: 3.375rem;
  grid-template-rows: auto;
  grid-template-columns: .75fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.cta_link {
  width: 5rem;
  height: 5rem;
  background-color: var(--marigold);
  color: var(--dark-brown);
  border-radius: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: static;
  top: 258px;
  left: 440px;
}

.cta_link-icon {
  height: 1.75rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.footer_first-widget {
  grid-column-gap: 2.625rem;
  grid-row-gap: 2.625rem;
  grid-template-rows: auto;
  grid-template-columns: auto 1fr auto;
  grid-auto-columns: 1fr;
  display: grid;
}

.footer_widget-divider {
  width: .125rem;
  background-image: linear-gradient(to bottom, rgba(106, 196, 137, 0), var(--golf-green) 50%, rgba(106, 196, 137, 0));
}

.footer_first-widget-inner {
  grid-row-gap: 21.4375rem;
  flex-direction: column;
  display: flex;
}

.footer_first-widget-inner-top {
  grid-row-gap: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.social-link-inner {
  grid-column-gap: .6875rem;
  grid-row-gap: .6875rem;
  color: var(--base-color-brand--white);
  align-items: center;
  font-weight: 500;
  text-decoration: none;
  display: flex;
}

.social-icon-wrap {
  width: 1.75rem;
  height: 1.75rem;
  background-color: var(--base-color-brand--white);
  color: var(--golf-green);
  border-radius: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.social-icon {
  width: .875rem;
  height: .875rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.footer_menu-widget {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.footer_menu-widget-inner {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.footer_menu-wrap {
  grid-row-gap: 1rem;
  flex-direction: column;
  display: flex;
}

.footer_menu-title {
  color: var(--base-color-brand--white);
  font-weight: 600;
}

.footer_menu-list {
  grid-row-gap: 1rem;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
}

.footer_menu-list-item {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.footer_menu-link {
  color: var(--base-color-brand--white);
  text-decoration: none;
  transition: all .3s;
}

.footer_menu-link:hover {
  color: var(--pistachio);
}

.copyright-wrap {
  grid-row-gap: 1rem;
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
}

.copyright {
  color: var(--base-color-brand--white);
}

.glowing-wrapper-button {
  z-index: 1;
  position: relative;
}

.glowing-wrapper {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.glowing-wrapper.glowing-wrapper-active.tagline {
  font-family: DM Mono, sans-serif;
}

.glowing-wrapper-borders-masker {
  width: 100%;
  opacity: 0;
  border-radius: 62.5rem;
  padding: .06rem;
  line-height: 100%;
  transition: opacity 1s;
  position: absolute;
  top: 0;
  left: 0;
}

.glowing-wrapper-animations {
  width: 100%;
  opacity: 0;
  transition: opacity 1s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.glowing-wrapper-mask {
  opacity: 1;
}

.glowing-wrapper-glow {
  opacity: .12;
  filter: blur(8px);
}

.glowing-wrapper-borders {
  width: 100%;
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.glowing-wrapper-mask-wrapper {
  opacity: 1;
}

.styles---glowing-button {
  display: none;
}

.navbar4_menu-wrapper-inner {
  flex-direction: column;
  display: flex;
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.25rem;
  }

  h4 {
    font-size: 1.75rem;
  }

  .hide-tablet {
    display: none;
  }

  .max-width-full-tablet {
    width: 100%;
    max-width: none;
  }

  .margin-large {
    margin: 2.5rem;
  }

  .margin-xlarge {
    margin: 3.5rem;
  }

  .margin-xxlarge {
    margin: 4.5rem;
  }

  .margin-huge {
    margin: 5rem;
  }

  .margin-xhuge {
    margin: 6rem;
  }

  .margin-xxhuge {
    margin: 7.5rem;
  }

  .padding-large {
    padding: 2.5rem;
  }

  .padding-xlarge {
    padding: 3.5rem;
  }

  .padding-xxlarge {
    padding: 4.5rem;
  }

  .padding-huge {
    padding: 5rem;
  }

  .padding-xhuge {
    padding: 6rem;
  }

  .padding-xxhuge {
    padding: 7.5rem;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .margin-left {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
  }

  .margin-right {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }

  .margin-vertical {
    margin-left: 0;
    margin-right: 0;
  }

  .margin-horizontal {
    margin-top: 0;
    margin-bottom: 0;
  }

  .padding-top {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .padding-bottom {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .padding-left {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
  }

  .padding-right {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-horizontal {
    padding-top: 0;
    padding-bottom: 0;
  }

  .heading-style-h2 {
    font-size: 2.75rem;
  }

  .rl-styleguide_icons-list {
    grid-auto-flow: row;
  }

  .heading-style-h1 {
    font-size: 3.25rem;
  }

  .heading-style-h4 {
    font-size: 1.75rem;
  }

  .heading-style-h3 {
    font-size: 2.25rem;
  }

  .padding-section-medium {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .padding-section-large {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .spacer-large {
    padding-top: 2.5rem;
  }

  .spacer-xlarge {
    padding-top: 3.5rem;
  }

  .spacer-xxlarge {
    padding-top: 4.5rem;
  }

  .spacer-huge {
    padding-top: 5rem;
  }

  .spacer-xhuge {
    padding-top: 6rem;
  }

  .spacer-xxhuge {
    padding-top: 7.5rem;
  }

  .menu-icon4 {
    width: 48px;
    height: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: -.5rem;
    padding-bottom: 0;
    padding-right: 0;
    display: flex;
    position: relative;
  }

  .menu-icon4_line-bottom {
    width: 24px;
    height: 2px;
    padding-bottom: 0;
    padding-right: 0;
  }

  .menu-icon4_line-middle {
    width: 24px;
    height: 2px;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
    margin-bottom: 6px;
    padding-bottom: 0;
    padding-right: 0;
    display: flex;
    position: static;
  }

  .menu-icon4_line-top {
    width: 24px;
    height: 2px;
    padding-bottom: 0;
    padding-right: 0;
  }

  .navbar4_link {
    margin-top: 0;
    margin-bottom: 0;
    padding: .75rem 0;
    position: static;
  }

  .menu-icon4_wrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  }

  .navbar4_menu-dropdown {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    display: block;
    position: relative;
  }

  .navbar4_container {
    grid-auto-columns: 1fr;
  }

  .navbar4_menu {
    z-index: 1;
    background-color: var(--taiga);
    -webkit-text-fill-color: inherit;
    background-clip: border-box;
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
    display: block;
  }

  .dropdown-icon {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
    top: auto;
    bottom: auto;
    left: auto;
    right: 0%;
  }

  .dropdown-icon.is-navbar4 {
    width: 1.25rem;
    height: 1.25rem;
  }

  .navbar4_dropdown-link {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 0;
    font-size: 20px;
  }

  .menu-icon_line-middle-base {
    width: 24px;
    height: 2px;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;
    padding-right: 0;
    display: flex;
    position: absolute;
  }

  .navbar4_dropdown-toggle {
    width: 100%;
    align-items: center;
    margin: 0;
    padding: .75rem 2rem .75rem 0;
    display: flex;
    position: relative;
  }

  .navbar4_dropdown-list {
    position: static;
    overflow: hidden;
  }

  .navbar4_dropdown-list.w--open {
    border-style: none;
    padding: 0;
    position: static;
  }


  .navbar4_menu-button.w--open {
    background-color: rgba(0, 0, 0, 0);
  }

  .menu-icon_line-middle-top {
    z-index: 2;
    width: 24px;
    height: 2px;
    padding-bottom: 0;
    padding-right: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .hero_component {
    grid-row-gap: 6.25rem;
  }

  .section_hero {
    padding-top: 10.4375rem;
  }

  .integration_grid {
    grid-column-gap: 2.5rem;
    grid-row-gap: 2.5rem;
    grid-template-columns: 1fr;
  }

  .integration_card {
    grid-column-gap: 3.75rem;
    grid-row-gap: 3.75rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    align-items: center;
    display: grid;
  }

  .integration_card-divider {
    width: 100%;
    height: .125rem;
    background-image: linear-gradient(to right, rgba(106, 196, 137, 0), var(--golf-green) 50%, rgba(106, 196, 137, 0));
  }

  .client_grid {
    grid-template-columns: 1fr;
  }

  .section-divider {
    width: 100%;
    height: .125rem;
    background-image: linear-gradient(to right, rgba(106, 196, 137, 0), var(--golf-green) 50%, rgba(106, 196, 137, 0));
  }

  .cta_title {
    margin-bottom: 1rem;
    font-size: 5.5rem;
  }

  .cta_component {
    margin-bottom: 7.5rem;
  }

  .cta_link {
    position: static;
  }

  .footer_menu-widget {
    grid-column-gap: 2.5rem;
    grid-row-gap: 2.5rem;
  }

  .footer_menu-widget-inner {
    grid-column-gap: 2.5rem;
    grid-row-gap: 2.5rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .footer_menu-widget-inner.bottom {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.5rem;
    line-height: 1.4;
  }

  h5 {
    font-size: 1.25rem;
  }

  h6 {
    font-size: 1.125rem;
  }

  .hide-mobile-landscape {
    display: none;
  }

  .max-width-full-mobile-landscape {
    width: 100%;
    max-width: none;
  }

  .margin-xsmall {
    margin: .75rem;
  }

  .margin-small {
    margin: 1.25rem;
  }

  .margin-medium {
    margin: 1.5rem;
  }

  .margin-large {
    margin: 2rem;
  }

  .margin-xlarge {
    margin: 2.5rem;
  }

  .margin-xxlarge {
    margin: 3rem;
  }

  .margin-huge {
    margin: 3.5rem;
  }

  .margin-xhuge {
    margin: 4rem;
  }

  .margin-xxhuge {
    margin: 5rem;
  }

  .padding-xsmall {
    padding: .75rem;
  }

  .padding-small {
    padding: 1.25rem;
  }

  .padding-medium {
    padding: 1.5rem;
  }

  .padding-large {
    padding: 2rem;
  }

  .padding-xlarge {
    padding: 2.5rem;
  }

  .padding-xxlarge {
    padding: 3rem;
  }

  .padding-huge {
    padding: 3.5rem;
  }

  .padding-xhuge {
    padding: 4rem;
  }

  .padding-xxhuge {
    padding: 5rem;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .margin-left {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
  }

  .margin-right {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }

  .margin-vertical {
    margin-left: 0;
    margin-right: 0;
  }

  .margin-horizontal {
    margin-top: 0;
    margin-bottom: 0;
  }

  .padding-top {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .padding-bottom {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .padding-left {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
  }

  .padding-right {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-horizontal {
    padding-top: 0;
    padding-bottom: 0;
  }

  .text-size-medium {
    font-size: 1rem;
  }

  .text-style-quote {
    font-size: 1.125rem;
  }

  .heading-style-h2 {
    font-size: 2.25rem;
  }

  .heading-style-h6 {
    font-size: 1.125rem;
  }

  .heading-style-h5 {
    font-size: 1.25rem;
  }

  .heading-style-h1 {
    font-size: 2.5rem;
  }

  .heading-style-h4 {
    font-size: 1.5rem;
    line-height: 1.4;
  }

  .heading-style-h3 {
    font-size: 2rem;
  }

  .text-style-nowrap {
    white-space: normal;
  }

  .text-size-large {
    font-size: 1.125rem;
  }

  .padding-section-small {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .padding-section-medium {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .padding-section-large {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .spacer-xsmall {
    padding-top: .75rem;
  }

  .spacer-small {
    padding-top: 1.25rem;
  }

  .spacer-medium {
    padding-top: 1.5rem;
  }

  .spacer-large {
    padding-top: 2rem;
  }

  .spacer-xlarge {
    padding-top: 2.5rem;
  }

  .spacer-xxlarge {
    padding-top: 3rem;
  }

  .spacer-huge {
    padding-top: 3.5rem;
  }

  .spacer-xhuge {
    padding-top: 4rem;
  }

  .spacer-xxhuge {
    padding-top: 5rem;
  }

  .hero_para {
    font-size: 1.25rem;
  }

  

  .client_grid {
    grid-column-gap: 1rem;
  }

  .client_card {
    grid-template-columns: 1fr;
  }

  .client_card._2 {
    padding-bottom: 11.875rem;
  }

  .client_card-thumbnail.absolute {
    height: 11.875rem;
  }

  .section_footer {
    padding-top: 3.75rem;
    padding-bottom: 2.5rem;
  }

  .cta_title {
    font-size: 4rem;
  }

  .cta_component {
    margin-bottom: 2.5rem;
  }

  .cta_content-shape {
    height: 18.75rem;
    top: 0%;
    bottom: 0%;
    left: auto;
    right: 0%;
  }

  .footer_component {
    grid-column-gap: 1rem;
    grid-row-gap: 2.5rem;
    grid-template-columns: 1fr;
  }

  .cta_link {
    width: 4rem;
    height: 4rem;
  }

  .cta_link-icon {
    height: 1.5rem;
  }

  .footer_first-widget {
    grid-column-gap: 1rem;
    grid-row-gap: 2.5rem;
    grid-template-columns: 1fr;
  }

  .footer_widget-divider {
    width: 100%;
    height: .125rem;
    background-image: linear-gradient(to right, rgba(106, 196, 137, 0), var(--golf-green) 50%, rgba(106, 196, 137, 0));
  }

  .footer_first-widget-inner, .footer_menu-widget-inner.bottom {
    grid-row-gap: 2.5rem;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.625rem;
  }

  .hide-mobile-portrait {
    display: none;
  }

  .margin-top {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .margin-left {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
  }

  .margin-right {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }

  .margin-vertical {
    margin-left: 0;
    margin-right: 0;
  }

  .margin-horizontal {
    margin-top: 0;
    margin-bottom: 0;
  }

  .padding-top {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .padding-bottom {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .padding-left {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
  }

  .padding-right {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-horizontal {
    padding-top: 0;
    padding-bottom: 0;
  }

  .max-width-full-mobile-portrait {
    width: 100%;
    max-width: none;
  }

  .button {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .button.is-navbar-button {
    width: 100%;
  }

  .button.is-navbar-button.desktop {
    display: none;
  }

  .button-group.hero_button-group {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    justify-content: center;
  }

  .tagline {
    font-size: .875rem;
  }

  .hero_para {
    font-size: 1rem;
  }

  .navbar4_menu-wrapper {
    justify-content: space-between;
    align-items: stretch;
    padding-top: 8rem;
    padding-bottom: 3rem;
  }

  .navbar4_logo {
    height: 2.25rem;
  }

  .hero_component {
    grid-row-gap: 2.875rem;
  }

  .hero_media.desktop {
    display: none;
  }

  .hero_media.mobile {
    display: inline-block;
  }

  .navbar-button-wrap-mobile {
    padding-top: 1rem;
    display: block;
  }

  .integration_card {
    grid-template-columns: 1fr;
  }

  .client_card {
    padding-left: 2rem;
  }

  .section_footer {
    padding-top: 2.5rem;
  }
}

#w-node-_374de02f-8d83-eae9-1b8a-23befe6bef30-acad4190, #w-node-_41c47c6d-614c-7985-1c39-e31eaeef8645-acad4190, #w-node-_978372b0-b0cc-6bc5-2b47-d509ce67f293-acad4190, #w-node-_04db09fc-9f33-301b-90ae-b14efa44608d-acad4190, #w-node-eafe1ddf-5777-bcb1-cd31-35d9bac3583f-acad4190 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-f3169a16-3d50-c74a-c4ce-0287e308d497-acad4190, #w-node-_1094f0d5-5b9d-4b92-1934-2322dfa8d448-acad4190, #w-node-e6e1f1ac-930b-b177-54d8-224cce19b48a-acad4190 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-cdce71c5-2429-936d-b2d3-e8f9498df05c-acad4190, #w-node-_21477a02-ad59-152c-b614-92b952da8b22-acad4190 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86205-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86207-acad41fa {
  justify-self: center;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8621b-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8621c-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86220-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86221-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86225-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86226-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8622a-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8622b-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8622f-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86230-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86234-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86235-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8623f-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86240-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86244-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86245-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86249-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8624a-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8624e-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8624f-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86253-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86254-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86258-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86259-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86263-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86264-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86268-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86269-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8626d-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8626e-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86272-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86273-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8627c-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8627d-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86286-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86287-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86299-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8629a-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8629e-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8629f-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862a3-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862a4-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862a8-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862a9-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862ad-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862ae-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862b8-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862b9-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862bd-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862be-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862c2-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862c3-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862c7-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862c8-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862cc-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862cd-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862d1-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862d2-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862dd-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862de-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862e2-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862e3-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862e7-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862e8-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862ec-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862ed-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862f1-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862f2-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862f6-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862f7-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862fb-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc862fc-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86300-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86301-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86305-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86306-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86310-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86311-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86315-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86316-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8631a-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8631b-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86322-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86323-acad41fa {
  justify-self: start;
}

#w-node-e9f32a64-fc89-70ea-ccef-8f5fa64bd5fd-acad41fa, #w-node-e9f32a64-fc89-70ea-ccef-8f5fa64bd600-acad41fa, #w-node-_5260568a-938d-ea68-0c74-308a8209edce-acad41fa, #w-node-_5260568a-938d-ea68-0c74-308a8209edd1-acad41fa, #w-node-_06b32c98-aea3-8ba0-a51d-a7fca0302b82-acad41fa, #w-node-_06b32c98-aea3-8ba0-a51d-a7fca0302b85-acad41fa, #w-node-e9f32a64-fc89-70ea-ccef-8f5fa64bd604-acad41fa, #w-node-e9f32a64-fc89-70ea-ccef-8f5fa64bd605-acad41fa, #w-node-e9f32a64-fc89-70ea-ccef-8f5fa64bd608-acad41fa, #w-node-e9f32a64-fc89-70ea-ccef-8f5fa64bd609-acad41fa, #w-node-e9f32a64-fc89-70ea-ccef-8f5fa64bd60c-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc86393-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86394-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8638e-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8638f-acad41fa {
  justify-self: start;
}

#w-node-_7304dac8-f593-d71d-1de4-011e92740d9b-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_7304dac8-f593-d71d-1de4-011e92740d9c-acad41fa {
  justify-self: start;
}

#w-node-c97d2f4c-a980-bb5b-78e5-2721ac744da4-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c97d2f4c-a980-bb5b-78e5-2721ac744da5-acad41fa {
  justify-self: start;
}

#w-node-_06aa1bf4-9138-5698-358d-7b7066841d42-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_06aa1bf4-9138-5698-358d-7b7066841d43-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc863ba-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc863bd-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc863be-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc863c1-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc863c2-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc863c5-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc863c6-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc863ca-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc863cd-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc863ce-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc863d1-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc863d2-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc863d5-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc863dd-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc863de-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc863df-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc863e1-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc863e4-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc863e5-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc863e6-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc863ea-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc863ed-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc863ee-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc863ef-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc863f3-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc863f7-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc863f8-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc863fe-acad41fa, #w-node-e9f171cc-11b4-0905-714d-593dc5e729cd-acad41fa, #w-node-e9f171cc-11b4-0905-714d-593dc5e729ce-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e9f171cc-11b4-0905-714d-593dc5e729cf-acad41fa {
  justify-self: start;
}

#w-node-e9f171cc-11b4-0905-714d-593dc5e729d3-acad41fa, #w-node-_803d93b2-f316-78e7-4b37-d40ff102217f-acad41fa, #w-node-_803d93b2-f316-78e7-4b37-d40ff1022180-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_803d93b2-f316-78e7-4b37-d40ff1022181-acad41fa {
  justify-self: start;
}

#w-node-_803d93b2-f316-78e7-4b37-d40ff1022187-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc86402-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86403-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86407-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc8640a-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc8640f-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc86410-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86411-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86415-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc86418-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc86419-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8641a-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86420-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc86423-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc86424-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86425-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8642b-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc8642e-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc8642f-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86430-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86438-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc8643b-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc8643c-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8643d-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86449-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc8644a-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8644b-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8644f-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc86454-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc86455-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86456-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8645c-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc86461-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc86462-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86463-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86469-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc8646e-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc8646f-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86470-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86478-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc8647e-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc8647f-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86485-acad41fa, #w-node-bf202792-073b-d0b9-2e3f-1ac68c32701c-acad41fa, #w-node-bf202792-073b-d0b9-2e3f-1ac68c32701d-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-bf202792-073b-d0b9-2e3f-1ac68c32701e-acad41fa {
  justify-self: start;
}

#w-node-bf202792-073b-d0b9-2e3f-1ac68c327022-acad41fa, #w-node-_7c4d1b7c-78f1-8ac7-381e-93ec539e1608-acad41fa, #w-node-_7c4d1b7c-78f1-8ac7-381e-93ec539e1609-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_7c4d1b7c-78f1-8ac7-381e-93ec539e160a-acad41fa {
  justify-self: start;
}

#w-node-_7c4d1b7c-78f1-8ac7-381e-93ec539e1610-acad41fa, #w-node-_45e7d798-6f20-3ddc-ae2f-bdd2974eece1-acad41fa, #w-node-_45e7d798-6f20-3ddc-ae2f-bdd2974eece2-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_45e7d798-6f20-3ddc-ae2f-bdd2974eece3-acad41fa {
  justify-self: start;
}

#w-node-_45e7d798-6f20-3ddc-ae2f-bdd2974eece9-acad41fa, #w-node-efb2dd60-0fd7-ccc6-83fa-c5f7f2777b7e-acad41fa, #w-node-efb2dd60-0fd7-ccc6-83fa-c5f7f2777b7f-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-efb2dd60-0fd7-ccc6-83fa-c5f7f2777b80-acad41fa {
  justify-self: start;
}

#w-node-efb2dd60-0fd7-ccc6-83fa-c5f7f2777b88-acad41fa, #w-node-_0a1c269a-9c65-cac5-d78d-08680a95253d-acad41fa, #w-node-_0a1c269a-9c65-cac5-d78d-08680a95253e-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0a1c269a-9c65-cac5-d78d-08680a95253f-acad41fa {
  justify-self: start;
}

#w-node-_0a1c269a-9c65-cac5-d78d-08680a952545-acad41fa, #w-node-ee513712-f44c-c02b-b1ea-6045fc69ab7c-acad41fa, #w-node-ee513712-f44c-c02b-b1ea-6045fc69ab7d-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-ee513712-f44c-c02b-b1ea-6045fc69ab7e-acad41fa {
  justify-self: start;
}

#w-node-ee513712-f44c-c02b-b1ea-6045fc69ab86-acad41fa, #w-node-_40872103-aff7-c010-da67-704a8f3f1108-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_40872103-aff7-c010-da67-704a8f3f1109-acad41fa {
  justify-self: start;
}

#w-node-_40872103-aff7-c010-da67-704a8f3f110f-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc8648f-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc86490-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc86491-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86492-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc86494-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc86497-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc8649b-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc8649c-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc8649d-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc864a6-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc864a7-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc864a8-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc864b1-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc864b2-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc864b3-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc864b4-acad41fa {
  justify-self: start;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc864ba-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc864bb-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc864bc-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc864bd-acad41fa {
  justify-self: start;
}

#w-node-da163454-3d7f-a6d2-8037-3b46b9c50abd-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc864c3-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc864c4-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc864c5-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_336be75c-ab2b-838b-5642-972b6cc864c6-acad41fa, #w-node-_336be75c-ab2b-838b-5642-972b6cc864c8-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369290-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369294-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369295-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369297-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369299-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936929a-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936929c-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936929e-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936929f-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692a1-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692a3-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692a4-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692a6-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692a8-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692a9-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692ab-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692ad-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692ae-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692b0-acad41fa, #w-node-_8ec19c33-ae2e-90c5-80bf-3c4e7d639cd4-acad41fa, #w-node-_450fae6e-ea6c-c71d-c007-cbe7f109d617-acad41fa, #w-node-dcc5fc80-562f-447f-055c-339db14678d8-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692b2-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692b3-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692b5-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692b6-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692b8-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692b9-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692bb-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692bc-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692be-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692bf-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692c1-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692c2-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692c4-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692c5-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692c7-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692cd-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692d1-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692d2-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692d4-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692d6-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692d7-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692d9-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692db-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692dc-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692de-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692e0-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692e1-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692e3-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692e5-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692e6-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692e8-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692ea-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692eb-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692ed-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692ef-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692f0-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692f2-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692f4-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692f5-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692f7-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692f9-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692fa-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692fc-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692fe-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593692ff-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369301-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369303-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369304-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369306-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936930b-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936930f-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369311-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369314-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369319-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a35936931e-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369323-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369328-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936932f-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369330-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369332-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369333-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369335-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369336-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369338-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369339-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a35936933b-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936933c-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936933e-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a35936933f-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369341-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369342-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369344-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369345-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369347-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369348-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936934a-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a35936934b-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a35936934d-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936934e-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369350-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369351-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369353-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369354-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369356-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369357-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369359-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936935a-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936935c-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a35936935d-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a35936935f-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369360-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369362-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369363-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369365-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369366-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369368-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369369-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a35936936b-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936936c-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936936e-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a35936936f-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369371-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369372-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369374-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369375-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369377-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369378-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936937a-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a35936937b-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a35936937d-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936937e-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369380-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369381-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369383-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369384-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369386-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369387-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a35936938c-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369390-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369392-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369395-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a35936939a-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a35936939f-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693a4-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693a9-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693b0-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693b1-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693b3-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693b4-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693b6-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693b7-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693b9-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693ba-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693bc-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693bd-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693bf-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693c0-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693c2-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693c3-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693c5-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693c6-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693c8-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693c9-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693cb-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693cc-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693ce-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693cf-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693d1-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693d2-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693d4-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693d5-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693d7-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693d8-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693da-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693db-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693dd-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693de-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693e0-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693e1-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693e3-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693e4-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693e6-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693e7-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693e9-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693ea-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693ec-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693ed-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693ef-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693f0-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693f2-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693f3-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693f5-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693f6-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693f8-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693f9-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693fb-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693fc-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693fe-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593693ff-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369401-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369402-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369404-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369405-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369407-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369408-acad41fa, #w-node-aba73718-5f03-827e-9e0b-61449dc1d37c-acad41fa, #w-node-_1a6e4211-c1a3-edfa-99df-59c05fb448f8-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1a6e4211-c1a3-edfa-99df-59c05fb448f9-acad41fa {
  justify-self: start;
}

#w-node-_1a6e4211-c1a3-edfa-99df-59c05fb448fb-acad41fa, #w-node-_1a6e4211-c1a3-edfa-99df-59c05fb448fc-acad41fa, #w-node-_16994295-7a31-57da-1001-8ec0ea846a1e-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_16994295-7a31-57da-1001-8ec0ea846a1f-acad41fa {
  justify-self: start;
}

#w-node-_16994295-7a31-57da-1001-8ec0ea846a21-acad41fa, #w-node-_16994295-7a31-57da-1001-8ec0ea846a22-acad41fa, #w-node-_8f691932-76bb-1979-3d19-a80adf0a25f2-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_8f691932-76bb-1979-3d19-a80adf0a25f3-acad41fa {
  justify-self: start;
}

#w-node-_8f691932-76bb-1979-3d19-a80adf0a25f5-acad41fa, #w-node-_8f691932-76bb-1979-3d19-a80adf0a25f6-acad41fa, #w-node-f28919c2-e180-d075-d1c2-81b601b8a964-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-f28919c2-e180-d075-d1c2-81b601b8a965-acad41fa {
  justify-self: start;
}

#w-node-f28919c2-e180-d075-d1c2-81b601b8a967-acad41fa, #w-node-f28919c2-e180-d075-d1c2-81b601b8a968-acad41fa, #w-node-b2bd7b9e-a5b1-0617-1861-ecb60c9b53b3-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-b2bd7b9e-a5b1-0617-1861-ecb60c9b53b4-acad41fa {
  justify-self: start;
}

#w-node-b2bd7b9e-a5b1-0617-1861-ecb60c9b53b6-acad41fa, #w-node-b2bd7b9e-a5b1-0617-1861-ecb60c9b53b7-acad41fa, #w-node-ee961e8d-845a-edc3-3307-509470022457-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-ee961e8d-845a-edc3-3307-509470022458-acad41fa {
  justify-self: start;
}

#w-node-ee961e8d-845a-edc3-3307-50947002245a-acad41fa, #w-node-ee961e8d-845a-edc3-3307-50947002245b-acad41fa, #w-node-_588dd3e3-4aa6-cc42-1280-2d37cff319e1-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_588dd3e3-4aa6-cc42-1280-2d37cff319e2-acad41fa {
  justify-self: start;
}

#w-node-_588dd3e3-4aa6-cc42-1280-2d37cff319e4-acad41fa, #w-node-_588dd3e3-4aa6-cc42-1280-2d37cff319e5-acad41fa, #w-node-_626bd165-37fe-b21e-713f-accb16c57414-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_626bd165-37fe-b21e-713f-accb16c57415-acad41fa {
  justify-self: start;
}

#w-node-_626bd165-37fe-b21e-713f-accb16c57417-acad41fa, #w-node-_626bd165-37fe-b21e-713f-accb16c57418-acad41fa, #w-node-_8198cc96-510b-d5a9-b63d-22b6607f5f40-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_8198cc96-510b-d5a9-b63d-22b6607f5f41-acad41fa {
  justify-self: start;
}

#w-node-_8198cc96-510b-d5a9-b63d-22b6607f5f43-acad41fa, #w-node-_8198cc96-510b-d5a9-b63d-22b6607f5f44-acad41fa, #w-node-_2a26aaa0-f27b-d541-a441-687d55aa9ee5-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_2a26aaa0-f27b-d541-a441-687d55aa9ee6-acad41fa {
  justify-self: start;
}

#w-node-_2a26aaa0-f27b-d541-a441-687d55aa9ee8-acad41fa, #w-node-_2a26aaa0-f27b-d541-a441-687d55aa9ee9-acad41fa, #w-node-_2abe4c27-4e03-1a95-b21e-aef8fb82fe33-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_2abe4c27-4e03-1a95-b21e-aef8fb82fe34-acad41fa {
  justify-self: start;
}

#w-node-_2abe4c27-4e03-1a95-b21e-aef8fb82fe36-acad41fa, #w-node-_2abe4c27-4e03-1a95-b21e-aef8fb82fe37-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593694fa-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a3593694fe-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a3593694ff-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369503-acad41fa, #w-node-_9a3cb5be-cc97-b70e-6a89-31a359369505-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369506-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936950a-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936950b-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936950f-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369510-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369514-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369515-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369519-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936951a-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936951e-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936951f-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369523-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369524-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369528-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369529-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936952d-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936952e-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369532-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369533-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936953a-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936953b-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936953f-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369540-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369544-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369545-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369549-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936954a-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936954e-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a35936954f-acad41fa {
  justify-self: start;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369553-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_9a3cb5be-cc97-b70e-6a89-31a359369554-acad41fa {
  justify-self: start;
}

#w-node-de8106d9-b098-80e3-076c-80f3a0fa5e54-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-de8106d9-b098-80e3-076c-80f3a0fa5e55-acad41fa {
  justify-self: start;
}

#w-node-_680345d6-d141-aefc-5e81-01faad69a792-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_680345d6-d141-aefc-5e81-01faad69a793-acad41fa {
  justify-self: start;
}

#w-node-dc962921-5c74-5687-77a4-c1371012b297-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-dc962921-5c74-5687-77a4-c1371012b298-acad41fa {
  justify-self: start;
}

#w-node-bbf5f4a2-0660-a8f8-7799-a71df583db57-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-bbf5f4a2-0660-a8f8-7799-a71df583db58-acad41fa {
  justify-self: start;
}

#w-node-_723dac19-3a6b-8f5e-89ce-53b9006ba92c-acad41fa {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_723dac19-3a6b-8f5e-89ce-53b9006ba92d-acad41fa {
  justify-self: start;
}

@media screen and (max-width: 991px) {
  #w-node-f3169a16-3d50-c74a-c4ce-0287e308d497-acad4190, #w-node-_1094f0d5-5b9d-4b92-1934-2322dfa8d448-acad4190, #w-node-e6e1f1ac-930b-b177-54d8-224cce19b48a-acad4190 {
    grid-column: span 1 / span 1;
  }
}


