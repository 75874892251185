.infoTable {
  max-height: 375px;

  /* Make it scrollable but the first line*/
  overflow-y: scroll;
  overflow-x: hidden;
}

.clientInfo {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  /* Fix heigh and make it scrollable */
  height: 85vh;
  overflow-y: scroll;
}

.colorTitle {
  background: #d9f6d8;
  border-radius: 5px 5px 0 0; /* Adjust the border-radius values for the desired roundness */
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
}

.acceptButton {
  box-sizing: border-box;

  background: rgba(4, 120, 242, 0.16);
  background: color(display-p3 0.204 0.463 0.918 / 0.16);
  border: 1px solid #0478f2;
  border: 1px solid color(display-p3 0.204 0.463 0.918);
  border-radius: 8px;
}

.acceptButtonText {
  color: #0478f2;
  color: color(display-p3 0.204 0.463 0.918);
}

.referButton {
  /* Frame 124 */
  box-sizing: border-box;

  background: rgba(255, 190, 56, 0.24);
  background: color(display-p3 0.961 0.757 0.333 / 0.24);
  /* #F5C155 */
  border: 1px solid #ffbe38;
  border: 1px solid color(display-p3 0.961 0.757 0.333);
  border-radius: 8px;
}

.referButtonText {
  color: #ffbe38;
  color: color(display-p3 0.961 0.757 0.333);
}

.declineButton {
  /* Frame 123 */
  box-sizing: border-box;

  background: rgba(235, 20, 41, 0.12);
  background: color(display-p3 0.847 0.204 0.204 / 0.12);
  /* #D83434 */
  border: 1px solid #eb1429;
  border: 1px solid color(display-p3 0.847 0.204 0.204);
  border-radius: 8px;
}

.declineButtonText {
  /* #D83434 */
  color: #eb1429;
  color: color(display-p3 0.847 0.204 0.204);
}
.button-download-intake {
  border-radius: 4px; /* Example of additional styling, adjust as needed */
  color: white; /* Text color */
}

.button-download-intake-accept {
  background-color: #68d568; /* Lighter green than before */
}

.button-download-intake-reject {
  background-color: #d32f2f; /* Lighter red than before */
}
